<template>
  <!-- 套餐管理 -->
  <div class="taocanManger">
    <div class="ml" style=" margin-top: 10px">
      <span style="margin-right: 10px">场地名称</span>
      <el-select v-model="addrname" placeholder="请输入场地名称" style="width: 150px" :disabled="!search">
        <el-option v-for="item in address_list" :key="item.id" :label="item.address_name" :value="item.id"></el-option>
      </el-select>
      <span style="margin:0 10px 0 30px">车辆类型</span>
      <el-select v-model="carttypekey" placeholder="请输入车辆类型" style="width: 150px" :disabled="!search">
        <el-option v-for="(item, index) in carttype" :key="index" :label="item" :value="index"></el-option>
      </el-select>
      <el-button type="primary" style="margin-left: 10px" @click="searcht2()" v-if="search">查询</el-button>
      <el-button type="warning" style="margin-left: 30px" @click="tuisearch2()" v-else>取消搜索</el-button>
    </div>
    <el-button
      type="primary"
      style="float: right;margin-bottom:10px"
      @click="
        dialogFormVisible = true;
        type = 'add';
        tingchedanwei = '时';
        chongdiandanwei = '时';
      "
      >+ 添加套餐</el-button
    >
    <!-- </el-menu> -->
    <el-table
      :data="tableData"
      border
      style="
        width: 100%;
        margin-top: 10px;
        max-height: 70vh;
        overflow-y: scroll;
      "
    >
      <el-table-column prop="package_name" label="套餐名字" width="160"></el-table-column>
      <!-- <el-table-column prop="created_at" label="添加时间" width="160"></el-table-column> -->
      <el-table-column prop="created_at" label="绑定场地" width="250">
        <template #default="scope" style="text-align:left">
          <div style="text-align:left">
            <div v-for="(item, index) in scope.row.address" :key="item.id">
              {{ index + 1 }}、{{ item.address_name }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="package_type" label="套餐类型"></el-table-column>
      <el-table-column prop="vehicle_type" label="车辆类型"></el-table-column>
      <el-table-column prop="price" label="价格"></el-table-column>
      <el-table-column prop="rjf_money" label="U币"></el-table-column>
      <el-table-column prop="charge_time" label="时长/次数(分钟)"></el-table-column>
      <el-table-column prop="charge_number" label="充电次数"></el-table-column>
      <el-table-column prop="parking_time" label="停车时长(分钟)"></el-table-column>
      <el-table-column prop="parking_number" label="停车次数"></el-table-column>
      <el-table-column prop="package_status" label="是否使用">
        <template #default="scope">
          <el-switch v-model="scope.row.package_status" active-color="#13ce66" @change="switchChange(scope.row)">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="套餐详情" width="250px"></el-table-column>
      <el-table-column prop="parking_type" label="停车类型"></el-table-column>
      <el-table-column label="操作" width="150px">
        <template #default="scope">
          <el-button @click="handleClick(scope.row)" type="primary" size="small">编辑</el-button>
          <el-button type="danger" size="small" @click.prevent="deleteRow(scope.$index, tableData)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :title="type == 'add' ? '添加套餐' : '修改套餐'" v-model="dialogFormVisible" width="400px">
      <el-form :model="form">
        <el-form-item label="套餐名称" :label-width="formLabelWidth">
          <el-input v-model="form.package_name" autocomplete="off" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="套餐类型" :label-width="formLabelWidth">
          <el-select v-model="form.package_type" placeholder="月卡/次卡" size="mini">
            <el-option v-for="item in tapcan_list" :key="item.id" :label="item.tapcan_name" :value="item.id">
            </el-option>
          </el-select>
          <el-select v-model="form.parking_type" placeholder="单停/充停" style="margin-top: 10px" size="mini">
            <el-option label="单停" value="0"></el-option>
            <el-option label="充停" value="1"></el-option>
            <el-option label="单充" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="金额" :label-width="formLabelWidth">
          <el-input v-model="form.price" autocomplete="off" size="mini"></el-input>
          <span>元</span>
        </el-form-item>
        <el-form-item label="	U币" :label-width="formLabelWidth">
          <el-input v-model="form.rjf_money" autocomplete="off" size="mini"></el-input>
          <span>个</span>
        </el-form-item>
        <el-form-item label="充电时长" :label-width="formLabelWidth">
          <el-input v-model="form.charge_time" autocomplete="off" style="width: 100px" size="mini"></el-input>
          <el-select
            v-model="chongdiandanwei"
            placeholder="筛选"
            style="width: 80px !important; margin-left: 10px !important"
            size="mini"
          >
            <el-option label="时" value="时"></el-option>
            <el-option label="天" value="天"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="充电次数" :label-width="formLabelWidth">
          <el-input v-model="form.charge_number" autocomplete="off" size="mini"></el-input>
          <span>次</span>
        </el-form-item>
        <el-form-item label="停车时长" :label-width="formLabelWidth">
          <el-input v-model="form.parking_time" autocomplete="off" style="width: 100px" size="mini"></el-input>
          <el-select
            v-model="tingchedanwei"
            placeholder="筛选"
            style="width: 80px !important; margin-left: 10px !important"
            size="mini"
          >
            <el-option label="时" value="时"></el-option>
            <el-option label="天" value="天"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="停车次数" :label-width="formLabelWidth">
          <el-input v-model="form.parking_number" autocomplete="off" size="mini"></el-input>
          <span>次</span>
        </el-form-item>
        <el-form-item label="绑定场地" :label-width="formLabelWidth">
          <el-select v-model="form.address_ids" multiple collapse-tags placeholder="请选择" size="mini">
            <el-option
              v-for="item in address_list"
              :key="item.address_name"
              :label="item.address_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="车辆类型" :label-width="formLabelWidth">
          <el-select placeholder="车辆类型" size="mini" v-model="form.vehicle_type">
            <el-option :label="item" :value="Number(index)" v-for="(item, index) in carttype" :key="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="套餐详情" :label-width="formLabelWidth">
          <el-input
            placeholder="每一小点请以中文分号' ；' 结束 例如 1、xxxxxx； 2、xxxxxx；"
            v-model="form.remark"
            :rows="2"
            type="textarea"
            size="mini"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            @click="
              dialogFormVisible = false;
              form = {};
            "
            >取 消</el-button
          >
          <el-button type="primary" @click="addcurse()">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { packageAdd, packageList, packageEdit, packageDel, packageFirst } from "../../request/api";
import { ElMessage } from "element-plus";
import { number } from "echarts";
export default {
  props: ["page", "pagepage"],
  data() {
    return {
      carttypekey: "",
      model: true,
      activeIndex: 1,
      tableDataCopy: [],
      addrname: "",
      search: true,
      tableData: [],
      dialogFormVisible: false,
      formLabelWidth: "100px",
      type: "add",
      vehicles_type: [],
      tapcan_list: [
        {
          id: "月卡",
          tapcan_name: "月卡",
        },
        {
          id: "次卡",
          tapcan_name: "次卡",
        },
      ],
      carttype: [],
      form: {
        package_name: "",
        package_type: "",
        price: "",
        rjf_money: "",
        charge_time: "",
        charge_number: "",
        parking_time: "",
        parking_number: "",
        package_status: 0,
        remark: "",
        parking_type: "",
        address_ids: [],
      },
      tingchedanwei: "时",
      chongdiandanwei: "时",
      address_list: [],
      pageTotal: "",
    };
  },
  async created() {
    this.packList();
    this.addlistsearch();
  },
  watch: {
    page(newName, oldName) {
      this.packList();
    },
    pagepage(newName, oldName) {
      this.packList();
    },
  },
  methods: {
    searcht2() {
      this.search = false;
      this.packList();
    },
    tuisearch2() {
      this.addrname = "";
      this.carttypekey = "";
      this.packList();
      this.search = true;
    },
    // created查询数据
    addlistsearch() {
      packageFirst().then((res) => {
        this.address_list = res.list.address_list;
      });
    },
    packList() {
      console.log(this.addrname, this.carttypekey);
      return new Promise((resolve, reject) => {
        packageList({
          page: this.page,
          page_size: this.pagepage,
          address_id: this.addrname,
          vehicle_type: this.carttypekey ? Number(this.carttypekey) : "",
        }).then((res) => {
          resolve(res.total);
          this.$emit("pagetotalClick", res.total);
          this.tableData = res.list.package_list;
          this.carttype = res.list.vehicles_type;
          this.tableData.forEach((item) => {
            item.package_type = item.package_type == 1 ? "月卡" : "次卡";
            item.package_status = item.package_status == 1 ? true : false;
            item.parking_type = item.parking_type == 1 ? "充停" : item.parking_type == 0 ? "单停" : "单充";
            item.vehicle_type = this.vehicleText(item.vehicle_type);
            item.charge_time = Math.round(item.charge_time / 60);
            item.parking_time = Math.round(item.parking_time / 60);
          });
          this.tableDataCopy = this.tableData;
        });
      });
    },
    vehicleText(type) {
      switch (type) {
        case 1:
          return "自行车";
        case 2:
          return "电瓶车";
        case 3:
          return "三轮车";
        case 4:
          return "滑板车";
        case 5:
          return "摩托车";
        default:
          return type;
      }
    },
    vehicleId(type) {
      switch (type) {
        case "自行车":
          return 1;
        case "电瓶车":
          return 2;
        case "三轮车":
          return 3;
        case "滑板车":
          return 4;
        case "摩托车":
          return 5;
        default:
          return type;
      }
    },
    switchChange(item) {
      this.handleClick(item);
      this.dialogFormVisible = false;
      this.form.package_status = item.package_status == true ? 1 : 0;
      this.addcurse();
    },
    //   删除
    deleteRow(index, rows) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          packageDel({ id: rows[index].id }).then((res) => {
            rows.splice(index, 1);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 弹窗确定按钮
    addcurse() {
      this.form.package_type = this.form.package_type == "月卡" ? 1 : 2;
      this.form.parking_type =
        this.form.parking_type == "充停"
          ? 1
          : this.form.parking_type == "单充"
          ? 2
          : this.form.parking_type == "单停"
          ? 0
          : this.form.parking_type;
      this.form.parking_time =
        this.tingchedanwei == "时"
          ? this.form.parking_time * 60 * 60
          : this.tingchedanwei == "天"
          ? this.form.parking_time * 24 * 60 * 60
          : "";
      this.form.charge_time =
        this.chongdiandanwei == "时"
          ? this.form.charge_time * 60 * 60
          : this.chongdiandanwei == "天"
          ? this.form.charge_time * 24 * 60 * 60
          : "";
      if (this.type == "add") {
        this.form.package_status = this.form.package_status == "使用中" ? 1 : 0;
        packageAdd(this.form).then((res) => {
          if (res.code == 200) {
            ElMessage.success({
              message: res.msg,
              type: "success",
            });
            this.packList();
            // this.form = {};
            this.dialogFormVisible = false;
          } else {
            this.form.package_type = this.form.package_type == 1 ? "月卡" : "次卡";
            this.form.package_status = this.form.package_status == 1 ? "使用中" : "未使用";
            this.form.parking_type =
              this.form.parking_type == 1 ? "充停" : this.form.parking_type == 2 ? "单充" : "单停";
            this.form.parking_time =
              this.tingchedanwei == "时"
                ? this.form.parking_time / 60 / 60
                : this.tingchedanwei == "天"
                ? this.form.parking_time / 60 / 60 / 24
                : "";
            this.form.charge_time =
              this.chongdiandanwei == "时"
                ? this.form.charge_time / 60 / 60
                : this.chongdiandanwei == "天"
                ? this.form.charge_time / 60 / 60 / 24
                : "";
            ElMessage.error(res.msg);
          }
        });
      } else if (this.type == "revise") {
        console.log("修改套餐");
        packageFirst({ id: 1 }).then((res) => {});
        this.form.package_status = this.form.package_status == true ? 1 : 0;
        this.form.vehicle_type = this.vehicleId(this.form.vehicle_type);
        packageEdit(this.form).then((res) => {
          if (res.code == 200) {
            ElMessage.success({
              message: res.msg,
              type: "success",
            });
            this.packList();
            this.form = {};
            this.dialogFormVisible = false;
          } else {
            this.form.package_type = this.form.package_type == 1 ? "月卡" : "次卡";
            this.form.package_status = this.form.package_status == 1 ? "使用中" : "未使用";
            this.form.parking_type =
              this.form.parking_type == 1 ? "充停" : this.form.parking_type == 2 ? "单充" : "单停";
            this.chongdiandanwei == "时"
              ? this.form.charge_time / 60 / 60
              : this.chongdiandanwei == "分"
              ? this.form.charge_time / 60
              : this.form.charge_time;
            this.form.parking_time =
              this.tingchedanwei == "时"
                ? this.form.parking_time / 60 / 60
                : this.tingchedanwei == "分"
                ? this.form.parking_time / 60
                : this.form.parking_time;
            ElMessage.error(res.msg);
          }
        });
      }
    },
    handleClick(item, index) {
      this.dialogFormVisible = true;
      this.form.id = item.id;
      this.form.package_name = item.package_name;
      this.form.package_type = item.package_type;
      this.form.price = item.price;
      this.form.rjf_money = item.rjf_money;
      this.form.charge_time = item.charge_time;
      this.form.charge_number = item.charge_number;
      this.form.parking_time = item.parking_time;
      this.form.parking_number = item.parking_number;
      this.form.package_status = item.package_status;
      this.form.remark = item.remark;
      this.form.parking_type = item.parking_type;
      this.form.vehicle_type = item.vehicle_type;
      this.type = "revise";
      this.tingchedanwei = "时";
      this.chongdiandanwei = "时";
      this.form.parking_time = (this.form.parking_time / 60).toFixed(2);
      this.form.charge_time = (this.form.charge_time / 60).toFixed(2);
      this.form.address_ids = [];
      packageFirst({ id: item.id }).then((res) => {
        res.list.package_list.address_packages.forEach((item) => {
          this.form.address_ids.push(item.pivot.address_id);
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.cell {
  text-align: center;
}

.el-dialog .el-input {
  width: 200px;
}

/deep/.el-dialog span {
  margin-left: 10px;
}

/deep/ .el-form-item {
  margin-bottom: 0;
}

/deep/ .el-dialog__body {
  padding: 0 20px;
}
</style>
